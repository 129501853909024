import React, { useState } from 'react'

import { DateInput, FormText } from '@te-digi/styleguide'

import { Code } from '../../components/Code'

const DateInputExample = () => {
  const [value, setValue] = useState('')

  return (
    <>
      <DateInput
        label="Päivämäärä"
        onChange={e => setValue(e)}
        onSelection={val => console.log('DateInput onSelection', val)}
        subLabel="pp.kk.vvvv"
        required
        value={value}
      />
      <FormText>
        Arvo: <Code>{JSON.stringify(value)}</Code>
      </FormText>
    </>
  )
}

export { DateInputExample }
