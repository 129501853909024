import React, { useState } from 'react'
import { format } from 'date-fns'

import {
  DateInput,
  FormGroup,
  List,
  ListItem,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { DateInputExample } from '../../examples/react/DateInputExample'
import { DateInputMultipleExample } from '../../examples/react/DateInputMultipleExample'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { Link } from '../../components/Link'
import { Color } from '../../components/Color'

const DATE_NOW = new Date()
const FORMATTED_DATE_NOW = format(DATE_NOW, 'd.M.yyyy')

const LAST_MONTH_DATE = new Date()
LAST_MONTH_DATE.setHours(0)
LAST_MONTH_DATE.setMinutes(0)
LAST_MONTH_DATE.setSeconds(0)
LAST_MONTH_DATE.setMilliseconds(0)
LAST_MONTH_DATE.setMonth(LAST_MONTH_DATE.getMonth() - 1)

const FORMATTED_LAST_MONTH_DATE = format(LAST_MONTH_DATE, 'd.M.yyyy')

const NEXT_MONTH_DATE = new Date()
NEXT_MONTH_DATE.setHours(0)
NEXT_MONTH_DATE.setMinutes(0)
NEXT_MONTH_DATE.setSeconds(0)
NEXT_MONTH_DATE.setMilliseconds(0)
NEXT_MONTH_DATE.setMonth(NEXT_MONTH_DATE.getMonth() + 1)

const FORMATTED_NEXT_MONTH_DATE = format(NEXT_MONTH_DATE, 'd.M.yyyy')

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => {
  const [value, setValue] = useState('')

  return (
    <Content
      heading="DateInput"
      components={[{ component: DateInput, restElement: 'Input' }]}
      status={[{ type: 'accessible', version: '16.3.0' }]}
    >
      <Section>
        <List variant="unordered">
          <ListItem>
            Tekstikenttään on mahdollista syöttää vain numeroita ja pisteitä.
          </ListItem>
          <ListItem>
            Päivämäärän formaatin ohjeistus sijoitetaan <Code>subLabel</Code>
            iin.
          </ListItem>
          <ListItem>
            Käyttäjän syötevirheisiin tulee reagoida virheviestein.
          </ListItem>
          <ListItem>
            Käyttäjän sallitaan syöttää päivämäärä etunollien kanssa (esim.
            01.01.2020, kun virallinen suositus on muoto 1.1.2020), päivämäärän
            tallennusvaiheessa etunollat siivotaan pois.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Playground
          enableOverflow
          example={DateInputExample}
          WrapperComponent={FormLayoutWrapper}
        />
      </Section>
      <Section title="Minimi- ja maksimipäivät kalenterissa">
        <Playground
          enableOverflow
          WrapperComponent={FormLayoutWrapper}
        >
          <DateInput
            label="Päivämäärä"
            maxDate={NEXT_MONTH_DATE}
            minDate={LAST_MONTH_DATE}
            onChange={e => setValue(e)}
            subLabel="pp.kk.vvvv"
            value={value}
          />
        </Playground>
      </Section>
      <Section title="Virheviestit">
        <List variant="unordered">
          <ListItem>
            Komponentille annettu arvo tulee validoida ja passata sen mukainen
            virheviesti <Code>error</Code>-propille. <Code>DateInput</Code> ei
            tee mitään validointia.
          </ListItem>
          <ListItem>
            Virheviestiä käyttäessä inputin reunaviiva näytetään{' '}
            <Color color="danger" />
            -värillä.
          </ListItem>
        </List>
        <SectionHeading>Päivämäärä on virheellisessä muodossa</SectionHeading>
        <Playground
          enableOverflow
          WrapperComponent={FormLayoutWrapper}
        >
          <DateInput
            label="Päivämäärä"
            subLabel="pp.kk.vvvv"
            error="Virheellinen päivämäärä. Anna päivämäärä muodossa pp.kk.vvvv."
            value="123.456.7890"
          />
        </Playground>
        <SectionHeading>
          Päivämäärä on <Code>minDate</Code> ja <Code>maxDate</Code> -arvojen
          ulkopuolella
        </SectionHeading>
        <Playground
          enableOverflow
          WrapperComponent={FormLayoutWrapper}
        >
          <>
            <FormGroup>
              <DateInput
                label="Päivämäärä"
                subLabel="pp.kk.vvvv"
                error={`Ensimmäinen sallittu päivä on ${FORMATTED_NEXT_MONTH_DATE}`}
                minDate={NEXT_MONTH_DATE}
                value={FORMATTED_DATE_NOW}
              />
            </FormGroup>
            <FormGroup noMargin>
              <DateInput
                label="Päivämäärä"
                subLabel="pp.kk.vvvv"
                error={`Viimeinen sallittu päivä on ${FORMATTED_LAST_MONTH_DATE}`}
                maxDate={LAST_MONTH_DATE}
                value={FORMATTED_DATE_NOW}
              />
            </FormGroup>
          </>
        </Playground>
      </Section>
      <Section title="Monta kenttää, yhteinen subLabel">
        <Paragraph>
          Toiston välttämiseksi (sekä visuaalisesti että ruudunlukijalla
          luettuna) esitetään ohjeistus ryhmän yhteisessä subLabelissa.
        </Paragraph>
        <Playground
          enableOverflow
          example={DateInputMultipleExample}
          WrapperComponent={FormLayoutWrapper}
        />
      </Section>
      <Section title="Disabloitu">
        <Paragraph>
          Ei-muokattava tieto tulisi yleensä esittää{' '}
          <Code>
            <Link page="Detail" />
          </Code>
          -komponentilla. Jos tieto on väliaikaisesti disabloitu (esim.
          lomakkeen muut valinnat vaikuttavat siihen, tarvitseeko tietoa
          syöttää), silloin voidaan käyttää disabloitua kenttää. Ruudunlukijalla
          disabled-kenttä hypätään yli.
        </Paragraph>
        <Playground
          enableOverflow
          WrapperComponent={FormLayoutWrapper}
        >
          <DateInput
            disabled
            label="Päivämäärä"
            subLabel="pp.kk.vvvv"
          />
        </Playground>
      </Section>
      <Section title="Lukittu">
        <Paragraph>
          Read-only-kenttää ei suositella käytettäväksi, vaan tulisi käyttää{' '}
          <Code>
            <Link page="Detail" />
          </Code>
          -komponenttia tai joissakin tapauksissa disabloitua. Lukittu kenttä on
          ruudunlukijalle hämmentävä, sillä se kuulostaa tavalliselta
          täytettävältä kentältä, read only -tieto luetaan ruudunlukijalla vasta
          viimeisenä kenttään tultaessa.
        </Paragraph>
        <Playground
          enableOverflow
          WrapperComponent={FormLayoutWrapper}
        >
          <DateInput
            label="Päivämäärä"
            readOnly
            value={FORMATTED_LAST_MONTH_DATE}
          />
        </Playground>
      </Section>
      <Section title="Saavutettavuus">
        <Paragraph noMargin>Tarkistuslista:</Paragraph>
        <List variant="unordered">
          <ListItem>
            Varmista, että päivämäärän syöttömuoto on määritetty komponentille.
          </ListItem>
          <ListItem>
            Päivämäärä tulee pystyä kirjoittamaan tai valitsemaan kalenterista.
          </ListItem>
        </List>
      </Section>
    </Content>
  )
}

export default Page
